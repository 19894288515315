import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"


const Station_ad = ({ data, location }) => (
  <Layout>
    <SEO 
      pagetitle="駅広告"
      pagedesc="駅広告はサインボード（駅看板）や駅ポスター広告、そしてSP広告などがあります。
      サインボードはシンプルに近隣に所在する企業の告知に適していて、駅ポスターは時期によってのキャンペーンや案内などの告知を
      幅広くそして費用負担軽めで展開するのに適しています。"
      pagepath={location.pathname}
      pageimg={data.station_ad.childImageSharp.original.src}
      pageimgw={data.station_ad.childImageSharp.original.width}
      pageimgh={data.station_ad.childImageSharp.original.height}

    />
    <section className="area">
    <div className="container">
      <h2 className="bar">駅広告</h2>
      <br />
      <figure>
        <GatsbyImage image={data.station_ad.childImageSharp.gatsbyImageData} alt="" />
      </figure>
      <br />
      <p className="article">
      <span className="article-sub">駅広告について</span><br />
      ・こちらのページは、長期に掲出して近隣の利用者に告知してくのに適しているサインボード（駅看板）や短期的にインパクトある告知に利用されるSP広告などがあります。
サインボード（駅看板）は、柱や壁面に埋め込まれている枠があり、LEDで内側から明るく照らして広告する媒体です。
SP広告は通路上部からフラッグを吊るしたり、エスカレータ壁面に長いシートや加工ポスターを長い面積をいっぱい使って広告したり、通路床面にシート貼り付けたりと様々な展開ができる広告商品が揃っています。また駅により設定があるのですが、利用者の多い改札や通路に集中的に複数の広告媒体でジャック的な展開ができる商品もあります。
サインボード（駅看板）は、継続的な長く反復的に浸透させるのに適していて、SP広告はターゲットやエリアを絞った短期集中展開に適しています。<br /><br />
サインボード（駅看板）は週に１回以上の接触者は、男性で55.2%、女性で46.9%となり全体では51.6%と５割を超える接触率となっていて
自然と目に入るというイメージがあるようです。駅構内の過ごし方として、１位のスマートフォンを見るに次ぎ、駅の看板を見るが２位に来ているのが要因とも言えます。
各SP広告は、様々な種類と展開箇所なので個別ごとに特定のデータまではありませんが、全体としては平均して35%程の接触率となっています。<br />
出典：Jeki首都圏移動者調査<br /><br />
また、広告接触率・商品興味関心度・商品購入喚起度の項目では、
『見た（見たような気がする）』『興味を持った』または『利用したい』と言う人の割合が５割を超えています。<br />
出典：交通広告共通指標策定調査<br /><br />

サインボード（駅看板）は、１ヶ月単位で、半年や年間ごとの継続利用が多いです。別途制作取付撤去費が必要にです。
広告したい時期の２ヶ月前から動いて調整していく感じになります。
SP広告は商品により一部除いて、基本７日単位での展開で、新商品や情報など告知した期間に合わせての利用が多いです。別途制作取付撤去費が必要になります。
広告展開したい時期の２ヶ月以上前からご相談頂いて調整していく感じになります。。
      </p>
    </div>
    </section>
    <section className="Advertising">
    <div className="container">
    <div className="block">
        <div className="ad-detail">
        <Link to={`/cat/stationAd_cat/`}>
        <button className="button-first">駅広告一覧へ</button>
        </Link>
        </div>
        <div className="ad-detail">
        <Link to={`/form/`}>
        <button className="button-second">お問い合わせ</button>
        </Link>
        </div>
    </div>
    </div>
    </section>
  </Layout>
)
  
export const query = graphql`
  query {
    station_ad: file(relativePath: {eq: "stationad.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        original {
          src
          height
          width
        }
      }
    }
  }
`

export default Station_ad